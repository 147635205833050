import { Container } from 'react-bootstrap'
import styles from './errorPage.module.scss'
import Image from 'next/future/image'
import { useTranslation } from 'react-i18next'

export default function Custom500() {
  const { t } = useTranslation('common')
  return (
    <Container className={styles.container}>
      <div className="mt-4 mb-5 w-100 d-flex flex-column">
        <h1 className="mt-5 d-flex justify-content-center">{t('pageNotFound')}</h1>
        <h5 className="mt-5 d-flex justify-content-center text-center">{t('checkUrl')}</h5>
        <Image src="/errorPage.svg" layout="cover" width={250} height={250} className="mt-5 w-100 px-2" />
      </div>
    </Container>
  )
}
